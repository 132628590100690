import React, { useEffect, useState, useRef } from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import './decoder.css'
import { Helmet } from 'react-helmet'
import { Button, Layout, theme } from 'antd'
import Banner from '../banner/Banner'
import common from '../../common/common'
import initTracker from '../../helper/initTracker'

const { Header, Content } = Layout

const JWTDecoder = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [jwt, setJwt] = useState(common.token)
  const [decodedToken, setDecodedToken] = useState(null)
  const [error, setError] = useState('')
  useEffect(() => {
    let page = "jwt"
     initTracker(page)
    const decoded = decodeJWT(jwt)
    if (decoded) {
      setDecodedToken(decoded)
    }
  }, [])
  useEffect(() => {
    const setFormattedDefaultJWT = () => {
      const parts = jwt.split('.')
      const colors = ['#fb015b', '#d63aff', '#00b9f1']
      if (jwtContainerRef.current) {
        jwtContainerRef.current.innerHTML = parts
          .map((part, index) => `<span style="color: ${colors[index]}">${part}</span>`)
          .join('<span style="color: #000">.</span>')
      }
    }

    setFormattedDefaultJWT()
  }, [jwt])

  const decodeJWT = (token) => {
    try {
      const [header, payload, signature] = token.split('.')

      if (!header || !payload || !signature) {
        throw new Error('Invalid JWT token')
      }

      const base64UrlDecode = (str) => {
        const base64 = str.replace(/-/g, '+').replace(/_/g, '/')
        return atob(base64)
      }

      const decodedHeader = JSON.parse(base64UrlDecode(header))
      const decodedPayload = JSON.parse(base64UrlDecode(payload))

      return {
        header: decodedHeader,
        payload: decodedPayload,
        signature: signature,
      }
    } catch (err) {
      setError('Error decoding JWT')
      return null
    }
  }

  const handleDecode = (jwt) => {
    setJwt(jwt)
    setError('')
    const decoded = decodeJWT(jwt)
    if (decoded) {
      setDecodedToken(decoded)
    }
  }
  const jwtContainerRef = useRef(null)

  const handlePaste = (event) => {
    event.preventDefault()
    const pastedText = event.clipboardData.getData('text')
    handleDecode(pastedText)
    const parts = pastedText.split('.')
    if (parts.length === 3) {
      const colors = ['#fb015b', '#d63aff', '#00b9f1']
      jwtContainerRef.current.innerHTML = parts
        .map((part, index) => `<span style="color: ${colors[index]}">${part}</span>`)
        .join('<span style="color: #000">.</span>')
    } else {
      jwtContainerRef.current.innerHTML = `<span style="color: red;">Invalid JWT Token</span>`
    }
  }
  return (
    <>
      <Helmet>
        <title>JWT Token Decoder Online</title>
        <meta name="author" content="Faraz Kamal" />
    <meta property="og:url" content="https://www.jstoolset.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="JSToolSet" />
    <meta content="JSON Web Token (JWT) is a compact URL-safe means of representing claims to be transferred between two parties. "></meta>
    <meta name="description" content="JSON Web Token (JWT) is a compact URL-safe means of representing claims to be transferred between two parties. "></meta>
      </Helmet>
      <Layout>
        <Layout>
          <Header style={{ padding: 0, background: '#242424', borderBottom: '1px solid rgba(74, 74, 74, 0.6)' }}>
            <h1 className="logo"> JSON Web Token Decoder</h1>
          </Header>
          <div className="jwt-main">
            <Banner />
          </div>
          <div className="main-container">
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                width: '80%',
              }}
            >
              <div className="token">
                <div className="jwt-decode">
                  <h1>ENCODED TOKEN</h1>
                  <div className="flex">
                    <div className="jwt-token">
                      <div className="textarea" ref={jwtContainerRef} contentEditable onPaste={handlePaste}></div>
                    </div>

                    <div className="jwt-output">
                      {decodedToken && (
                        <>
                          <div className="jwt-header">
                            <h4>HEADER:ALGORITHM & TOKEN TYPE</h4>
                            <pre className="header-token ">{JSON.stringify(decodedToken.header, null, 2)}</pre>
                          </div>
                          <div className="jwt-header">
                            <h4>PAYLOAD:DATA</h4>
                            <pre className="payload"> {JSON.stringify(decodedToken.payload, null, 2)}</pre>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
                </div>
                <div className="jwt-definition">
                  <div id="header">
                    <h1>JWT Decoder</h1>
                    <h1 style={{opacity:0,position:'absolute'}}>JWT token Decoder</h1>
                    <p>JWT Decoder , including oauth bearer tokens. Save results and share URL with others. Free.</p>
                  </div>
                  <h2>What is a JWT?</h2>
                  <p>
                    JSON Web Tokens (JWTs) are a widely adopted method for securely transmitting information between
                    systems. They are compact, URL-safe tokens that consist of three parts: a header, a payload, and a
                    signature. JWTs play a crucial role in enabling stateless communication between systems by allowing
                    the exchange of authenticated and trusted information.
                  </p>
                </div>
                <div className="jwt-definition">
                  <h1>JWT encoding and decoding</h1>

                  <p>
                    In the encoding and decoding process of a JWT, the algorithm plays a crucial role in ensuring the
                    integrity and authenticity of the token. JWTs utilize cryptographic algorithms to create and verify
                    the signature, which is a critical component of the token.
                  </p>
                  <p>
                    When encoding a JWT, the algorithm is selected and specified in the header of the token. Common
                    algorithms used for signature generation include{' '}
                    <a href="/docs/reference/password-hashes#salted-hmac-sha-256">
                      HMAC (Hash-based Message Authentication Code)
                    </a>{' '}
                    and <a href="/docs/reference/password-hashes#salted-hmac-sha-256">RSA (Rivest-Shamir-Adleman)</a>.
                    The algorithm takes the header and payload of the token, combines them, and applies a secret key or
                    private key to generate a unique signature. This signature is appended to the JWT, creating a
                    tamper-proof token.
                  </p>
                  <p>
                    During the decoding process, the algorithm specified in the JWT’s header is used to verify the
                    signature. The recipient of the token uses the corresponding secret key or public key associated
                    with the algorithm to validate the signature. By re-computing the signature using the same
                    algorithm, the recipient can compare it with the received signature. If they match, it indicates
                    that the JWT has not been tampered with and that the data it contains can be trusted.
                  </p>
                  <p>
                    Online JWT decoder lets you examine the contents of any JWT by pasting it into the Token form
                    field. It’ll automatically decode the values and place the header and body into the respective
                    fields. The choice of algorithm depends on the specific security requirements and constraints of the
                    system. HMAC algorithms are symmetric, meaning the same secret key is used for both encoding and
                    decoding. RSA algorithms, on the other hand, are asymmetric, utilizing a public key for verification
                    and a private key for signing. The algorithm used should be strong enough to resist attacks and
                    ensure the integrity and confidentiality of the data within the JWT.
                  </p>
                </div>
              </div>
            </Content>
          </div>
        </Layout>
      </Layout>
    </>
  )
}

export default JWTDecoder
