const initTracker = async (page) => {
    const gasContactURL =
      "https://script.google.com/macros/s/AKfycbzIu9v2_AYCAwUgCocPwxs6bREF-CHcDbsGElcw2b6_lRMTN74l8uj_G09kUYAHkffV/exec";
    
    const formData = new FormData();
    formData.append("Email", "user");
    formData.append("Page", page);
    formData.append("Type", "userInfo");
  
    try {
      const response = await fetch(gasContactURL, {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json(); 
      } else {
        console.error("HTTP Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
    }
  };

  export default initTracker;
  