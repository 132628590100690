import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Terms from './pages/Terms'
import MP4Converter from './components/pages/MP4Converter'
import JWTDecoder from './components/pages/JWTDecoder'
import TwitterMediaDownloader from './pages/TwitterMediaDownloader'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App></App>} />
        <Route path="/mp4-converter" element={<MP4Converter></MP4Converter>} />
        <Route path="/terms" element={<Terms></Terms>} />
        <Route path="/jwt-decoder" element={<JWTDecoder/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
